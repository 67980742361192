import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "generate-browser-check"
    }}>{`Generate Browser Check`}</h1>
    <p>{`Our `}<a parentName="p" {...{
        "href": "https://kuali-build.zendesk.com/hc/en-us/articles/360038452214-Supported-Browsers"
      }}>{`official policy`}</a>{` is to support the 2 most recent versions of Chrome, Firefox, Safari, and Microsoft Edge. We use `}<a parentName="p" {...{
        "href": "https://github.com/browserslist/browserslist-useragent-regexp"
      }}><inlineCode parentName="a">{`browserslist-useragent-regexp`}</inlineCode></a>{` to generate a regular expression that matches this browser support.`}</p>
    <p><inlineCode parentName="p">{`browserslist-useragent-regexp`}</inlineCode>{` uses the `}<inlineCode parentName="p">{`caniuse-lite`}</inlineCode>{` database to determine what the 2 most recent versions are, so it's also important that `}<inlineCode parentName="p">{`caniuse-lite`}</inlineCode>{` is updated regularly.`}</p>
    <p>{`The script also uses the `}<inlineCode parentName="p">{`unreleased versions`}</inlineCode>{` browserslist query to allow beta, alpha, development, canary, etc. versions of browsers. These aren't officially supported, but the people who install and use these versions are typically developers or have more technical knowledge.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <ol>
      <li parentName="ol">{`Ensure `}<inlineCode parentName="li">{`caniuse-lite`}</inlineCode>{` is up to date (run `}<inlineCode parentName="li">{`npx browserslist@latest --update-db`}</inlineCode>{` to update `}<inlineCode parentName="li">{`caniuse-lite`}</inlineCode>{`, if needed)`}</li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`yarn generate-browser-check`}</inlineCode>{` or `}<inlineCode parentName="li">{`node scripts/generate-browser-check`}</inlineCode>{` (they'll both do the same thing)`}</li>
      <li parentName="ol">{`If successful, a message will be displayed stating, "Browser check generated and saved to: " with the path of the generated file`}</li>
      <li parentName="ol">{`Commit the updated file (and the `}<inlineCode parentName="li">{`caniuse-lite`}</inlineCode>{` update, if needed)`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      